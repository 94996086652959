.elementor-bc-flex-widget
  .elementor-7069
  .elementor-element.elementor-element-4a17f40b.elementor-column
  .elementor-widget-wrap {
  align-items: space-evenly;
}
.elementor-7069
  .elementor-element.elementor-element-4a17f40b.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: space-evenly;
  align-items: space-evenly;
}
.elementor-7069
  .elementor-element.elementor-element-4a17f40b
  > .elementor-element-populated {
  padding: 30px 30px 30px 80px;
}
.elementor-7069 .elementor-element.elementor-element-52b3f87b {
  text-align: left;
}
.elementor-bc-flex-widget
  .elementor-7069
  .elementor-element.elementor-element-5226f482.elementor-column
  .elementor-widget-wrap {
  align-items: flex-end;
}
.elementor-7069
  .elementor-element.elementor-element-5226f482.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: flex-end;
  align-items: flex-end;
}
.elementor-7069
  .elementor-element.elementor-element-5226f482
  > .elementor-element-populated {
  padding: 25px 25px 25px 25px;
}
.elementor-7069 .elementor-element.elementor-element-4a70bceb {
  padding: 0px 0px 10px 0px;
}
.elementor-7069
  .elementor-element.elementor-element-549c08b7
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 5px;
}
.elementor-7069 .elementor-element.elementor-element-5bfff29e {
  columns: 1;
  column-gap: 0px;
  color: var(--e-global-color-primary);
  font-family: Tohoma;
  font-size: 16px;
  line-height: 1em;
}
.elementor-7069
  .elementor-element.elementor-element-664f6eb5
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 5px;
}
.elementor-7069 .elementor-element.elementor-element-4caec896 {
  color: var(--e-global-color-primary);
  font-family: Tohoma;
  font-size: 16px;
  line-height: 1em;
}
.elementor-7069
  .elementor-element.elementor-element-4caec896
  > .elementor-widget-container {
  padding: 0px 0px 0px 10px;
}
.elementor-bc-flex-widget
  .elementor-7069
  .elementor-element.elementor-element-628a3e94.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-7069
  .elementor-element.elementor-element-628a3e94.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-7069
  .elementor-element.elementor-element-628a3e94
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 80px;
}
.elementor-7069 .elementor-element.elementor-element-889dc24 {
  color: var(--e-global-color-primary);
  font-family: "Karla", Tohoma;
  font-size: 12px;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-5f17611.elementor-social-icon {
  background-color: rgba(0, 0, 0, 0);
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-5f17611.elementor-social-icon
  i {
  color: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-5f17611.elementor-social-icon
  svg {
  fill: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-6795417.elementor-social-icon {
  background-color: rgba(0, 0, 0, 0);
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-6795417.elementor-social-icon
  i {
  color: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-6795417.elementor-social-icon
  svg {
  fill: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-87a19c6.elementor-social-icon {
  background-color: rgba(255, 255, 255, 0);
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-87a19c6.elementor-social-icon
  i {
  color: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-87a19c6.elementor-social-icon
  svg {
  fill: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-1013a6b.elementor-social-icon {
  background-color: rgba(120, 190, 32, 0);
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-1013a6b.elementor-social-icon
  i {
  color: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-1013a6b.elementor-social-icon
  svg {
  fill: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-5cedab4.elementor-social-icon {
  background-color: rgba(120, 190, 32, 0);
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-5cedab4.elementor-social-icon
  i {
  color: #78be20;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-repeater-item-5cedab4.elementor-social-icon
  svg {
  fill: #78be20;
}
.elementor-7069 .elementor-element.elementor-element-1f1cef74 {
  --grid-template-columns: repeat(0, auto);
  --icon-size: 30px;
  --grid-column-gap: 0px;
  --grid-row-gap: 0px;
}
.elementor-7069
  .elementor-element.elementor-element-1f1cef74
  .elementor-widget-container {
  text-align: left;
}
.elementor-7069:not(.elementor-motion-effects-element-type-background),
.elementor-7069
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: lightgray;
  background-image: url("https://min.streetdrone.com/wp-content/uploads/2020/07/h09-tire.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 1024px) {
  .elementor-7069:not(.elementor-motion-effects-element-type-background),
  .elementor-7069
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
@media (max-width: 767px) {
  .elementor-7069
    .elementor-element.elementor-element-4a17f40b
    > .elementor-element-populated {
    padding: 25px 25px 25px 25px;
  }
  .elementor-7069
    .elementor-element.elementor-element-628a3e94
    > .elementor-element-populated {
    padding: 0px 0px 0px 25px;
  }
  .elementor-7069:not(.elementor-motion-effects-element-type-background),
  .elementor-7069
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
