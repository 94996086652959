th {
  color: #78BE20
}

.btn-primary {
  background-color: #78BE20 !important;
}

.green{
  color: #78BE20;
  font-weight: bold;
}

.yellow{
  color: orange;
  font-weight: bold;
}

.red{
  color: red;
  font-weight: bold;
}

.float-child {
  width: 50%;
  float: left;
  padding: 10px;
}  

.alert {
  margin-bottom: 1px;
  padding:5px 5px !important;
}
.alert-dismissible .btn-close {
  padding: 10px !important;
}
.error-only-checkbox {
  padding-bottom: 10px;
  float: right;
  padding-right: 10px;
  cursor: pointer;
}